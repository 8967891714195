import { JSEncrypt } from 'jsencrypt'
import httpProxy from './request.js'

export default {
    data() {
        return {
            mixinViewModuleOptions: {
                getListUrl: '', //获取列表数据api地址
                deleteUrl: '', //删除数据api地址
                changeStatusUrl: '', //修改状态api地址
                addUrl: '', //新增api地址
                editUrl: '', //修改api地址
                batchAddUrl: '', //批量新增api地址
                batchDeleteUrl: '', //批量删除api地址
                batchChangeStatusUrl: '', //批量修改状态api地址
                batchOrderUrl:'',
                batchKey: 'Id', //批量操作key值
                downloadUrl: 'api/FileUpload/Download', //下载地址
                resetUrl: '', //还原数据api，
                collectUrl:'',//学生端收藏url
                cancelCollectUrl:'',//学生端取消收藏
                teacherListUrl:'api/UserManager/TeachersDictionary',//教师字典列表
                classListUrl:'api/Classes/Dictionary',//班级字典列表
                deleteAttachment:'api/FileUpload/DeleteFile',
                nationListUrl:'api/Classes/Nation'
            },
           
            dataForm: {}, //需要操作的form表单
            dataListSelections: [], //选中数组
            time:'',
            minutes:'',
            date:''
        }
    },
    filters: {
        num: (val) => {
          val = '' + val // 转换成字符串
          let int = val
          int = int.split('').reverse().join('') // 翻转整数
          let temp = '' // 临时变量
          for (let i = 0; i < int.length; i++) {
            temp += int[i]
            if ((i + 1) % 3 === 0 && i !== int.length - 1) {
              temp += ',' // 每隔三个数字拼接一个逗号
            }
          }
          temp = temp.split('').reverse().join('') // 加完逗号之后翻转
          return temp // 返回
        }
      },
    methods: {
        // 加密
        encryptedData(data) {
            // 新建JSEncrypt对象
            var crypt = new JSEncrypt();
            crypt.setPublicKey("MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHJh1I4ykpy7jg4+TJiJOFWaiXmW" +
                "rC+eG1Sf9zYSP/mtCPXuN2drbe9VwqKTRba/QHPyTWI+uB2s7s/xrQB03cdc8XLH" +
                "/l9m1PkKGVD6szyV8zjK0gTRr20FY0niO7RfKa/7va9ma8WAXJIkGsPGW19m7UTq" +
                "i8IgCt+u+GGb/XADAgMBAAE=");
            // let encryptor = new JSEncrypt();
            // 设置公钥
            //  encryptor.setPublicKey(publicKey);
            // 加密数据
            return crypt.encrypt(data);
        },
        // 下载
        handleDownload(api, params) {
            let fileName = params.FileName;
            httpProxy.downloadFile(api,params).then(res => {
                const content = res;
                const blob = new Blob([content.data]);
                if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
                } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
                }
            })
                .catch(function() {
            });
        },
         // 获取院系
         getDepart() {
            //1代表教部
            httpProxy.get('api/FacultyGetAll').then(
                rs => {
                    this.options = rs.data;
                }
            )
        },
        // 获取民族
        getNationList(){
            httpProxy.get(this.mixinViewModuleOptions.nationListUrl).then(
                rs=>{
                    this.nationOption = rs.data;
                }
            )
        },
        // 时间日期格式化
        dateFormat(dateString){
            if(dateString==='' || dateString==="" || dateString===undefined ||dateString===null){
                return "";
            }
            var newDate=new Date(dateString);
            let month =(newDate.getMonth()+1)<10?'0'+(newDate.getMonth()+1):(newDate.getMonth()+1);
            let date =(newDate.getDate())<10?'0'+(newDate.getDate()):(newDate.getDate());
            return newDate.getFullYear()+"-"+month+"-"+date;
        },
        // 时间日期格式化年月
        dateFormatMonth(dateString){
            if(dateString==='' || dateString==="" || dateString===undefined ||dateString===null){
                return "";
            }
            var newDate=new Date(dateString);
            let month =(newDate.getMonth()+1)<10?'0'+(newDate.getMonth()+1):(newDate.getMonth()+1);
            return newDate.getFullYear()+"-"+month;
        },
        // 时间日期格式化年月
        dateFormatDate(dateString){
            if(dateString==='' || dateString==="" || dateString===undefined ||dateString===null){
                return "";
            }
            var newDate=new Date(dateString);
          
            return newDate.getDate();
        },
        // 时间日期格式化时分秒
        dateTimeFormat(dateString){
            if(dateString==='' || dateString==="" || dateString===undefined ||dateString===null){
                return "";
            }
            var newDate=new Date(dateString);
            let month =(newDate.getMonth()+1)<10?'0'+(newDate.getMonth()+1):(newDate.getMonth()+1);
            let date =(newDate.getDate())<10?'0'+(newDate.getDate()):(newDate.getDate());
            let hour =(newDate.getHours())<10?'0'+(newDate.getHours()):(newDate.getHours());
            let minute =(newDate.getMinutes())<10?'0'+(newDate.getMinutes()):(newDate.getMinutes());
            let second =(newDate.getSeconds())<10?'0'+(newDate.getSeconds()):(newDate.getSeconds());
            return newDate.getFullYear()+"-"+month+"-"+date+'  '+hour+':'+minute+":"+second;
        },
        // 时间日期格式化
        updateTime: function() {
			var me = this;
            var cd = new Date();
            var week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            this.time = me.zeroPadding(cd.getHours(), 2) + ':' + me.zeroPadding(cd.getMinutes(), 2) + ':' + me.zeroPadding(cd.getSeconds(), 2) + '  '+ week[cd.getDay()];
            this.minutes = me.zeroPadding(cd.getHours(), 2) + ':' + me.zeroPadding(cd.getMinutes(), 2) + ':' + me.zeroPadding(cd.getSeconds(), 2);
            this.date = me.zeroPadding(cd.getFullYear(), 4) + '-' + me.zeroPadding(cd.getMonth()+1, 2) + '-' + me.zeroPadding(cd.getDate(), 2) + '  ';

        },
        zeroPadding: function(num, digit) {
			var zero = '';
			for(var i = 0; i < digit; i++) {
				zero += '0';
			}
			return (zero + num).slice(-digit);
		},
        formatTextarea(string){
            if(string){
                return string.replace(/[\n]/gi, "<br/>").replace(/[\r]/gi, "<br/>").replace(/[\s]/gi, "&nbsp;")
            }else{
                return ''
            }
            
        },
        
    },
}